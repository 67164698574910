import React from 'react';
import * as LucideIcons from 'lucide-react';
import type { LucideIcon } from 'lucide-react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { StatusOption } from './types/tables';

interface StatusDialogProps {
    isOpen: boolean;
    onClose: (event?: React.MouseEvent) => void;
    onSave: (status: number, notes: string, event?: React.MouseEvent) => void;
    currentStatus: number;
    currentName: string;
    currentNotes: string;
    options: StatusOption[];
}

const StatusDialog = ({
                          isOpen,
                          onClose,
                          onSave,
                          currentStatus,
                          currentName,
                          currentNotes,
                          options,
                      }: StatusDialogProps) => {
    const [selectedStatus, setSelectedStatus] = React.useState<number>(currentStatus);
    const [notes, setNotes] = React.useState(currentNotes);

    React.useEffect(() => {
        if (isOpen) {
            setSelectedStatus(currentStatus);
            setNotes(currentNotes);
        }
    }, [isOpen, currentStatus, currentNotes]);

    const handleClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        onClose(event);
    };

    const handleSave = (event: React.MouseEvent) => {
        event.stopPropagation();
        onSave(selectedStatus, notes, event);
    };

    const handleDialogClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            onClick={handleDialogClick}
        >
            <DialogTitle>Update Status: {currentName}</DialogTitle>
            <DialogContent>
                <FormControl component="fieldset" sx={{ width: '100%', mt: 1 }}>
                    <RadioGroup
                        value={selectedStatus.toString()}
                        onChange={(e) => setSelectedStatus(Number(e.target.value))}
                    >
                        {options.map((option, index) => {
                            const IconComponent = LucideIcons[option.icon as keyof typeof LucideIcons] as LucideIcon;
                            return (
                                <FormControlLabel
                                    key={index}
                                    value={index.toString()}
                                    control={<Radio />}
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            {IconComponent && (
                                                <IconComponent size={18} color={option.color} />
                                            )}
                                            <span>{option.text}</span>
                                        </Box>
                                    }
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>

                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    label="Notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Add any additional notes..."
                    onClick={(e) => e.stopPropagation()}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit">
                    Cancel
                </Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default StatusDialog;